// Request
import { doRequest } from '@/utils/requestApi'

/**
 * Registramos token del dispositivo del usuario para enviar notificaciones a este
 *
 * @param {String} token - tokend del dispositivo a registrar
 * @param {String} placeId - UID del establecimiento asociado
 * @param {String} userId - UID del usuario asociado
 * @return {Object} - server response
 */
export async function registerDeviceToken(token, placeId, userId) {
  const result = await doRequest({
    method: 'post',
    url: 'v1/notification',
    data: {
      data: {
        token,
        placeId,
        userId
      }
    }
  })

  return result.data
}
