// Constant
import { ADDONS } from '@/constants'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
// Filters
import { formatDate, formatPhoneNumber } from '@/filters'
import { orderStatusColor, orderTypesLabel, orderStatusLabel } from '../../../../filters'
// Utils
import { get } from 'lodash'
// Vuex
import { mapState } from 'vuex'

export default {
  name: 'TakeAwayOrderDataTableItem',
  props: {
    items: {
      required: true,
      type: Array,
      default() {
        return []
      }
    },
    loading: {
      required: true,
      type: Boolean,
      default: true
    }
  },
  filters: { formatDate, formatPhoneNumber, orderStatusColor, orderTypesLabel, orderStatusLabel },
  mixins: [addonsMixin],
  computed: {
    ...mapState('app', ['extraSmallDevice']),
    /**
     * Obtenemos el pais del establecimiento
     * @return {string}
     */
    countryCodePlace() {
      return get(this.placeAddonsSetupByUser, `${ADDONS.contact}.place.country_short`, 'ES')
    },
    /**
     * Opciones del botón de acción
     *
     * @return {object}
     */
    vButtonOptions() {
      return {
        color: 'primary',
        elevation: 0,
        icon: true
      }
    }
  },
  methods: {
    /**
     * Cuando pulsamos sobre un item
     *
     * @param {Object} id - UID del pedido
     */
    handleClickItem(id) {
      this.$emit('onClickItem', id)
    }
  }
}
