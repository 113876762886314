// Constants
import { ADDONS } from '@/constants'
import { ORDER_STATUS } from '../../constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import TakeAwayOrderDataTableItem from '../../components/elements/orders/TakeAwayOrderDataTableItem'
import VuetifyDataTable from '@/components/ui/VuetifyDataTable'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import addonMixin from '@/mixins/addonsMixin'
import takeAwayMixin from '../../mixins/takeAwayMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters, mapState } from 'vuex'
// Services
import { registerDeviceToken } from '@/services/notification'
// Utils
import { get, isNil } from 'lodash'
// Notifications
import * as PN from '@/utils/push-notifications'

export default {
  name: 'TakeAwayOrders',
  components: {
    CardContainer,
    TakeAwayOrderDataTableItem,
    VuetifyDataTable,
    VuetifyToolBar
  },
  mixins: [addonMixin, takeAwayMixin, uiMixin],
  data() {
    return {
      // Table
      headers: [
        {
          text: 'Pedido',
          value: 'orderId'
        },
        {
          text: 'Nombre',
          value: 'name'
        },
        {
          text: 'Teléfono',
          value: 'phone'
        },
        {
          text: 'Tipo',
          value: 'type'
        },
        {
          text: 'Fecha',
          value: 'orderTimestamp'
        },
        {
          text: 'Estado',
          align: 'center',
          value: 'status'
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false
        }
      ],
      // Others
      currentDate: new Date(),
      items: [],
      formProcessing: false,
      onlyPreOrders: this.$route.params.preorder ? true : false,
      pushNotificationsStatusIsEnabled: PN.hasSupport() && PN.status() === 'granted'
    }
  },
  computed: {
    ...mapGetters('takeAway', ['takeAwayOrders', 'takeAwayPreOrders', 'takeAwayHasMenu']),
    ...mapGetters('place', ['placeData']),
    ...mapGetters('authentication', ['userData']),
    ...mapState('app', ['extraSmallDevice']),
    /**
     * Alerta de posible notificación
     *
     * @return {boolean}
     */
    showNotificationAlert() {
      return !this.pushNotificationsStatusIsEnabled
    },
    /**
     * Mostramos filtros de pre-order
     *
     * @return {boolean}
     */
    showOnlyPreOrders() {
      return get(this.placeAddonsSetupByUser, `${ADDONS.takeAway}.preorderEnabled`, false)
    },
    /**
     * Get pending orders to show in the list
     *
     * @return {Array} - orders
     */
    pendingOrders() {
      return this.items.filter((order) => {
        return order.status === ORDER_STATUS.pending.value
      })
    },
    /**
     * Get "no finished" orders to show in the list
     *
     * @return {Array} - orders
     */
    noFinishedOrders() {
      return this.items.filter((order) => {
        return (
          order.status === ORDER_STATUS.accepted.value ||
          order.status === ORDER_STATUS.sent.value ||
          order.status === ORDER_STATUS.inProcess.value
        )
      })
    },
    /**
     * Opciones para la tabla
     *
     * @returns {Object}
     */
    dataTableVOptions() {
      return {
        'hide-default-footer': true,
        'disable-pagination': true
      }
    }
  },
  watch: {
    // Comprobamos si el usuario tiene menu "takeAway"
    takeAwayHasMenu: {
      async handler(value) {
        if (!isNil(value) && value === false) {
          this.showDialogInitialTakeAwaySetup()
        }
      },
      immediate: true
    },
    // Cada vez que cambiemos el activador de "pre-pedidos"
    onlyPreOrders(value) {
      this.setItems(value)
    },
    // Cada vez que entran pre-pedidos nuevos
    takeAwayPreOrders() {
      this.setItems(this.onlyPreOrders)
    },
    // Cada vez que entran pedidos nuevos
    takeAwayOrders() {
      this.setItems(this.onlyPreOrders)
    },
    /**
     * Watch the router because we use the same component
     * to differents routes and we need that it can reload itself
     */
    async $route(to) {
      this.onlyPreOrders = to.params.preorder ? true : false
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Cuando pulsamos sobre el botón de acción de la
     * alerta de notificaciones
     */
    async handleClickNotifications() {
      try {
        // Loading...
        this.formProcessing = true

        // Pedimos permiso de notificación
        const result = await PN.requestPermission()

        if (result) {
          // Obtenemos token
          const token = await PN.getToken()
          // Almacenamos en Firestore
          const { ok } = await registerDeviceToken(token, this.placeData.id, this.userData.id)

          if (!ok) {
            throw new Error('No se ha podido registrar el dispositivo correctamente.')
          }

          // Proceso finalizado correctamente
          this.pushNotificationsStatusIsEnabled = true

          this.modifyAppAlert({
            text: 'Notificaciones activadas correctamente en el dispositivo',
            type: 'info',
            visible: true
          })
        }
      } catch (error) {
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        // Loading...
        this.formProcessing = false
      }
    },
    /**
     * Cuando pulsamos sobre un item del listado
     *
     * @param {string} id - UID pedido
     */
    handleClickItem(id) {
      if (id) {
        this.routerPushTo({
          name: 'takeAwayOrderDetail',
          params: { id }
        })
      }
    },
    /**
     * Obtiene todos los datos necesarios para la vista
     */
    async getEveryNeededData() {
      this.setItems(this.onlyPreOrders)
    },
    /**
     * Establecemos los items a mostrar
     *
     * @param {boolean} onlyPreOrders - Mostrar solo "pre-orders"
     */
    setItems(onlyPreOrders = true) {
      this.items = onlyPreOrders ? this.takeAwayPreOrders : this.takeAwayOrders
    }
  }
}
